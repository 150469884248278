import * as _types2 from "@babel/types";

var _types = "default" in _types2 ? _types2.default : _types2;

var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = annotateAsPure;
var _t = _types;
const {
  addComment
} = _t;
const PURE_ANNOTATION = "#__PURE__";

const isPureAnnotated = ({
  leadingComments
}) => !!leadingComments && leadingComments.some(comment => /[@#]__PURE__/.test(comment.value));

function annotateAsPure(pathOrNode) {
  const node = pathOrNode["node"] || pathOrNode;

  if (isPureAnnotated(node)) {
    return;
  }

  addComment(node, "leading", PURE_ANNOTATION);
}

export default exports;
export const __esModule = exports.__esModule;